import { utilityText } from '../components/admin/common/msgText';
import { popupNotification } from '../components/admin/services/CustomerServices';

/**
 * @description the useTimer is a custom hook which provide us functionality to check for timeout activity
 */
const useTimer = () => {

  /**
  * @description the tracker check any kind of activity on web page
  */
  const tracker = () => {
    window.addEventListener('scroll', resetCounter, false);
    window.addEventListener('click', resetCounter, false);
    window.addEventListener('keypress', resetCounter, false);
  }
  var x: any;

  /**
  * @description reset the counter for inactivity and restarts the counter again
  */
  const resetCounter = () => {
    clearInterval(x);
    counter();
  }
  /**
  * @description timer for the counter initializes here
  */
  const counter = () => {
    tracker()
    //15 minutes refresh time
    var countDownDate = new Date().getTime() + 15 * 60 * 1000;
    const handleRefresh = () => {
      window.location.href ="/";
    }
    //counter function
    x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // If the count down is finished, write some text
      if (distance < 0) {
        //reset timer when over
        clearInterval(x);
        //call warning modal
        popupNotification(utilityText.timeoutTitle, utilityText.timeoutMsg, handleRefresh, '')
      };
    });
  }
  return {
    counter, tracker
  }

}
export default useTimer