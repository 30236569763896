
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";

import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import { useDispatch, useSelector } from "react-redux";

import { AuthActions } from "../redux/Auth";
import { RootState } from "../redux/createStore";
import { AccountInfo } from "@azure/msal-browser";

/**
 * @description
 * @returns
 */
const useAuthService = () => {
  const { instance, accounts } = useMsal();
  const isUserAuthenticatedFromAzure = useIsAuthenticated();

  const dispatch = useDispatch();
  const { isAuthenticated, userProfile } = useSelector(
    (state : RootState) => state.Auth
  );

  /**
   * @description fetches user profile data from Azure AD (only when user is logged in)
   *              and stores in state "userProfileData"
   * @returns
   */
  const requestProfileData = () => {
    // if user is not logged in, we should not get profile data.
    if (!isUserAuthenticatedFromAzure) return null;

    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0] as AccountInfo,
      })
      .then((authresponse) => {       
        callMsGraph(authresponse.accessToken).then((response) =>          
          dispatch(
            AuthActions.setUserAuthenticated({
              isAuthenticated: isUserAuthenticatedFromAzure,
              userProfile: {
                idToken: authresponse.idToken,
                givenName: response.givenName,
                surname: response.surname,
              },
            })
          )
        );
      });
  };

  /**
   * @description will redirect/popup to microsot login screen
   */
  const login = () => {
   
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  /**
   * @description will logout selected user from current browser
   */
  const logout = () => {
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(
        `${process.env.REACT_APP_TENENT_ID}`
      ),
     // postLogoutRedirectUri: `${process.env.REACT_APP_DOMAIN_URL}`, 
     postLogoutRedirectUri: `${process.env.REACT_APP_DOMAIN_URL}`,      
      onRedirectNavigate: (url) => {
        console.log(url);
      },
    });   
  };

  return {
    instance,
    isUserAuthenticatedFromAzure,
    isAuthenticated,
    userProfile,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    requestProfileData,
    login,
    logout,
  };
};

export default useAuthService;
