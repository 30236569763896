import useDashboard from "../hooks/useDashboard"
import { useState } from "react";
import Search from "antd/es/input/Search";
import Tooltip from 'antd/es/tooltip';
import { tooltipMsg, warningText } from "../components/admin/common/msgText";
import { isValid, popupNotification } from "../components/admin/services/CustomerServices";

const SearchBox = (props: any) => {  
  //calling reacthook
  const { searchCustomers } = useDashboard();
  const [search, setSearch] = useState('')
  const [buttonClick, setButtonClick] = useState(0)
  const [focused, setFocused] = useState(false)
  const [popupVisiblity,setPopupVisiblity]=useState(false)
  //Setting search state and checking if limit exceeds display popups
  const searchOrder = (e: any) => {
    if (e.target.value) {
      e.target.value = e.target.value.replace(" ", "")
    }
    if (e.target.value.length > searchLimit  && !popupVisiblity) {
      setPopupVisiblity(true)   
      e.target.value = e.target.value.slice(0, searchLimit)     
      popupNotification(warningText.limitExceededTitle, warningText.limitExceeded, ()=>{}, 'dispatch')
    }else{
      setPopupVisiblity(false)  
    }
  }
  //search Limit
  const searchLimit = 10;
  const invokeSearch = (e: any) => {
    if (buttonClick === 0) {
      searchCustomers(e)
      setButtonClick(1)
      setTimeout(() => {
        setButtonClick(0)
      }, 2000)
    }
  }
  return (
    <Tooltip placement="right" title={tooltipMsg} visible={search.length === 0 && focused ? true : false} >
      <Search
        placeholder="Search Order"
        allowClear
        enterButton="Search"
        size="large"
        onInput={searchOrder}
        onChange={(e) => setSearch(e.target.value)}
        onMouseOver={() => setFocused(true)}
        onMouseOut={() => setFocused(false)}
        onSearch={(e) => {
          isValid(search, e)
          invokeSearch(e)
        }
        }
      />
    </Tooltip>
  );
};
export default SearchBox;