import Table from "antd/es/table";
import Tag from "antd/es/tag"
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import { getDealerName, getNotificationMSG, getOptInData, getTag } from "./services/CustomerServices";
import "./styles/notification.css";
import { Customer } from "./types/Customer";
import Moment from 'react-moment';
import useDashboard from "../../hooks/useDashboard";


/**
 * @description View All Notification  tab component
 * @returns 
 */
const NotificationList = (props: any) => {

  // get redux state from customer
  const { filterdCustomers } = useSelector(
    (state: RootState) => state.Customer
  );

  //get render pagination function from the custom hook 
  const { renderPagination } = useDashboard();
  return (
    <>
      <Table key="notification_table"
        dataSource={getOptInData(filterdCustomers)}
        pagination={{
          defaultPageSize: props.pagesize,
          itemRender: (current, type, originalElement) =>
            renderPagination(current, type, originalElement),
          className: 'pagination'
        }}>
        <Column
          title="Dealer / Customer Name"
          dataIndex='dealer_name'
          key="dealer_name"
          render={(text, filterdCustomers: Customer) => getDealerName(text) + ' / ' + filterdCustomers.customer_first_name}
        />
        <Column
          title="Order #"
          dataIndex="order_id"
          key="order_id"
          sorter={(a: Customer, b: Customer) =>
            parseInt(a.order_id) - parseInt(b.order_id)
          }
          sortDirections={["descend", "ascend"]}
          render={(text) => text}
        />
        <Column
          title="Order Status"
          dataIndex="status"
          key="status"
          render={(status: string) => <Tag color={getTag(status)} key={status}>
            {status.toUpperCase()}
          </Tag>}
        />
        <Column
          title="SMS / Email"
          dataIndex="notification_channel"
          key="notification_channel"

        />
        <Column title="Message" dataIndex="status"
          key="status"
          render={(status: string) => getNotificationMSG(status)}
        />
        <Column
          title="Notification Date"
          dataIndex="timestamp"
          key="timestamp"
          render={(timestamp: string) => <Moment format="MM-DD-YYYY HH:mm a">
            {timestamp}
          </Moment>}
        />
      </Table>
    </>
  )
};
export default NotificationList;
