import React from "react";
import { Header } from "antd/lib/layout/layout";
import "./styles/header.css"
import Row  from "antd/es/row";
import Col  from "antd/es/col";
import Button  from "antd/es/button";
import Menu  from "antd/es/menu";
import Dropdown from "antd/es/dropdown"
import UserOutlined  from "@ant-design/icons/UserOutlined";
import SearchBox from "./SearchBox";
import useAuthSerice from "../hooks/useAuthService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/createStore";
// @ts-ignore
import logo from './assets/acertus.jpg';

/**
 * @description HeaderMenu component 
 */
const HeaderMenu = () => {
  //// get redux state from the redux hook
  const { logout, requestProfileData } = useAuthSerice();
  // get redux state from auth user
  const { userProfile } = useSelector((state: RootState) => state.Auth);
  //using react hooks to get logged in user data
  React.useEffect(() => {
    requestProfileData();
  }, []);

   const dispatch = useDispatch()
   //creating dropdown for logged in user
  const userProfileMenu = (
    <Menu key="menu_bar">      
      <Menu.Item key="1">
        <Button key="logout" type="link" onClick={() => logout()}>
          Logout
        </Button>
      </Menu.Item>     
    </Menu>
  );

  return (
    <Header className="header-div">
      <Row>
        <Col span={3}>
          <img src={logo} className="brand-logo" alt="Acertus logo" />
        </Col>
        <Col span={6}></Col>
        <Col className="search-container" span={5}>
          <SearchBox  dispatch={dispatch}/>
        </Col>
        <Col span={4}></Col>
        <Col span={5}>
          <span className="logged-user">
            Hi {userProfile.surname || "Guest"} (admin)
          </span>
        </Col>
        <Col className="dropdown-menu" span={1}>
          <Dropdown overlay={userProfileMenu} trigger={["click"]}>
            <a
              href="#"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <UserOutlined />
            </a>            
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
export default HeaderMenu;
