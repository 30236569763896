/**
 * @description utility text is a centralized location for setting and getting texts
 */
export const utilityText = {
    timeoutTitle: 'Session Timeout!',
    timeoutMsg: 'Session over due to inactivity.\nRefresh to load page.',
}

export const sortKey = "timestamp"

export const warningText = {
    popupLocation: 'topRight',
    dataNotFoundText: 'Data not found, try another search',
    invalidSearchText: 'Order Id should be a number',
    dataNotFoundTitle: 'Search Error',
    invalidSearchTitle: 'No Results Found',
    limitExceededTitle: 'Order length Exceeded',
    limitExceeded: 'Max Order length is 10 characters.',
    emptySearch: "Please provide Order No"
}

//tooltip message
export const tooltipMsg = "Please provide complete Order No"

export const popupTitle = {
    limitPopup: 'limitPopup',
    emptySearch: 'emptySearch',
    invalidSearch: 'invalidSearch',
    dataNotFound: 'dataNotFound'
}
export const timeoutTime=3000