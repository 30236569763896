import Table from "antd/es/table/Table";
import Tag from "antd/es/tag"
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import { getDealerName, getTag } from "./services/CustomerServices";
import { Customer } from "./types/Customer";
import Moment from 'react-moment';
import useDashboard from "../../hooks/useDashboard";

/**
 * @description Opt In/ Opt Out tab component
 * @returns 
 */
const OptStatus = (props: any) => {

  // get redux state from customer
  const { filterdCustomers } = useSelector(
    (state: RootState) => state.Customer
  );

  //get render pagination function from the custom hook 
  const { renderPagination } = useDashboard();
  return (
    <>
      <Table key="opt_status_table"
        dataSource={filterdCustomers}
        pagination={{
          defaultPageSize: props.pagesize,
          itemRender: (current, type, originalElement) =>
            renderPagination(current, type, originalElement),
          className: 'pagination'
        }}>
        <Column
          title="Dealer / Customer Name"
          dataIndex="dealer_name"
          key="dealer_name"
          render={(text, filterdCustomers: Customer) => getDealerName(text) + ' / ' + filterdCustomers.customer_first_name}
        />
        <Column
          title="Order #"
          dataIndex="order_id"
          key="order_id"
          sorter={(a: Customer, b: Customer) =>
            parseInt(a.order_id) - parseInt(b.order_id)
          }
          sortDirections={["descend", "ascend"]}
          render={(text) =>text}          
        />
        <Column
          title="Order Status"
          dataIndex="status"
          key="status"
          render={(order_status: string) => <Tag color={getTag(order_status)} key={order_status}>
            {order_status.toUpperCase()}
          </Tag>}
        />
        <Column title="Opt In/ Opt Out" dataIndex="notification_subscription_status" key="notification_subscription_status" />
        <Column title="SMS / Email" dataIndex="notification_channel" key="notification_channel" />
        <Column title="Last Updated" dataIndex="timestamp" key="timestamp" render={(timestamp: string) => <Moment format="MM-DD-YYYY HH:mm a">
          {timestamp}
        </Moment>} />
      </Table>
    </>
  );
};
export default OptStatus;
