import Row from "antd/es/row";
import Tabs from "antd/es/tabs"
import NotificationList from "./NotificationList";
import OptStatus from "./OptStatus";
import "./styles/dashboard.css";
import { FC, useEffect } from "react";
import useDashboard from '../../hooks/useDashboard'
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import useTimer from "../../hooks/useTimer";
import { changeTab, loadApp } from "./services/CustomerServices";

const { TabPane } = Tabs;

/**
 * @description  Dashboard component is the container for all tables and tabs
 * @returns 
 */
const Dashboard: FC = () => {

  // get redux state from customer
  const { getCustomers, allCustomers, isLoading, activeTab } = useDashboard();
  // get counter from use timer custom hook
  const { counter } = useTimer();
  //call auth hook to get idtoken and send it a param for getting all customer
  const { userProfile } = useSelector((state: RootState) => state.Auth);
  const token = userProfile.idToken
  const dispatch = useDispatch()
  //loading api on page load
  useEffect(() => {
    loadApp(token,allCustomers,counter,getCustomers)
  }, [token]);

  return (
    <Row key="dashboard-wrapper" className="dashboard-wrapper">
      {!isLoading ?
        <Tabs defaultActiveKey={activeTab} onChange={(e: any) => changeTab(e, dispatch)} >
          <TabPane tab="View All Notifications" key="1">
            <NotificationList pagesize="10" />
          </TabPane>
          <TabPane tab="Opt in / Opt out Status" key="2">
            <OptStatus pagesize="10" />
          </TabPane>
        </Tabs> :
        <Space size="middle">
          <Spin size="large" />
        </Space>
      }
    </Row>
  );
};
export default Dashboard;
