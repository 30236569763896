import Col from 'antd/es/col';
import { Footer } from 'antd/lib/layout/layout';
import './styles/footer.css'

const FooterMenu = () => {
    return (
        <Footer key="footer" className="footer">
            <Col key="privacy" span={24}>
                <span><small><a target="_blank" title="View Privacy" className="footer_link" href="https://acertusdelivers.com/privacy-policy/">Privacy</a></small></span>
                <span><small className="right">&copy; 2021 ACERTUS All Rights Relentlessly Reserved</small></span>
            </Col>
        </Footer>
    );
}
export default FooterMenu