import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../components/admin/types/Customer";



const initialStats= {  
  customer_first_name: "",
  customer_last_name: "",
  dealer_name: "",
  notification_channel: "",
  notification_subscription_status: "",
  order_id: "",
  status: "",
  timestamp: "",  
  vinlocity_id:""
}

const urlToken={
  randomUrl:"3hsj87"
}
interface IUrl{
  randomUrl:string
}
export interface IPopupState { 
    isModalVisible: boolean;  
    modalStatus:Customer;
    randomUrl:IUrl
}

const initialState: IPopupState = {
    isModalVisible: false,
    modalStatus:initialStats,
    randomUrl:urlToken
};

const PopupSlice = createSlice({
  name: "PopupDetails",
  initialState: initialState,
  reducers: {
    toggleModalVisible: (state, action: PayloadAction) => {
      state.isModalVisible = !state.isModalVisible;
    },    
    setModalStatus: (state, action: PayloadAction<Customer>) => {
      state.modalStatus = action.payload;
    },   
    setRandomUrl:(state,action:PayloadAction<IUrl>) =>{
      state.randomUrl=action.payload
    }
  },
});

const PopupReducer = PopupSlice.reducer;
const PopupActions = PopupSlice.actions;
export { PopupReducer, PopupActions };
